import Nav from '../components/Nav/index';
import { useParams } from 'react-router-dom';

export default function BlogPost() {
  const { post } = useParams();

  return (
    <div className="BlogPost">
      <Nav />
      <div className="row">
        <div className="column column-20" />
        <div className="column column-60">
          <h1>{post}</h1>
        </div>
        <div class="column column-20" />
      </div>
    </div>
  );
}
