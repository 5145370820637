import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

export default function Nav() {
  return (
    <div className="navContainer">
      <div class="row">
        <div class="column column-20" />
        <div class="column column-60">
          <Link to="/" className="navLink">
            <b className="homeTitle">GameTitle</b>
          </Link>
          <Link to="/blog" className="navLink">blog</Link>
          <Link to="/qa" className="navLink">qa</Link>
          <Link to="/downloads" className="navLink">downloads</Link>
        </div>
        <div class="column column-20" />
      </div>
    </div>
  );
}
