import Nav from '../components/Nav/index';

export default function Downloads() {
  return (
    <div className="Downloads">
      <Nav />
      <div className="row">
        <div className="column column-20" />
        <div className="column column-60">
          <h5>Downloads</h5>
        </div>
        <div class="column column-20" />
      </div>
    </div>
  );
}
