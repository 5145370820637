import React from 'react';
import './style.css';

export default function Photo({ url, subheading }) {
  return (
    <div>
      <img width="600" src={url} />
      <div className="subHeading">{">> " + subheading}</div>
      <div>..........</div>
      <br />
    </div>
  );
}
