import Nav from '../components/Nav/index';
import { Link } from 'react-router-dom';

export default function Blog() {
  return (
    <div className="NoMatch">
      <Nav />
      <div className="row">
        <div className="column column-20" />
        <div className="column column-60">
            <h1>404 :(</h1>
        </div>
        <div class="column column-20" />
      </div>
    </div>
  );
}
