import Nav from '../components/Nav/index';

export default function Home() {
  return (
    <div className="App">
      <Nav />
      <div className="row">
        <div className="column column-20" />
        <div className="column column-60">
          <div>fdasf</div>
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
            WOWOWOWOW
          </a>
          <hr />
          <p>The base type is 1.6rem (16px) over 1.6 line height (24px)</p>
          <a>Anchor</a>
          <em>Emphasis</em>
          <small>Small</small>
          <strong>Strong</strong>
          <u>Underline</u>
          <h1>Heading</h1>
          <h2>Heading</h2>
          <h3>Heading</h3>
          <h4>Heading</h4>
          <h5>Heading</h5>
          <h6>Heading</h6>
        </div>
        <div class="column column-20" />
      </div>
    </div>
  );
}
