import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

export default function BlogItem({ link, date, title }) {
  return (
    <div>
      <span className="itemDate">{date} | </span>
      <Link className="itemLink" to={'/blog/' + link}>
        {title}
      </Link>
      <br />
    </div>
  );
}
