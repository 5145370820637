import Nav from '../components/Nav/index';

export default function QA() {
  return (
    <div className="QA">
      <Nav />
      <div className="row">
        <div className="column column-20" />
        <div className="column column-60">
          <h5>Heading</h5>
          <h6>Heading</h6>
          <u>Underline</u>
        </div>
        <div class="column column-20" />
      </div>
    </div>
  );
}
