import Nav from '../../components/Nav/index';
import BlogTitle from '../../components/BlogTitle/index';
import Photo from '../../components/Photo/index';
import { useParams } from 'react-router-dom';

export default function DevPost2() {
  return (
    <div className="BlogPost">
      <Nav />
      <div className="row">
        <div className="column column-20" />
        <div className="column column-60">
          <BlogTitle title={'Dev Progress 2'} date={'October 26th, 2022'} />
          <Photo url={'https://images2.imgbox.com/40/e5/zlUQ6urb_o.png'} subheading={'Blocking out intro scene'} />
        </div>
        <div class="column column-20" />
      </div>
    </div>
  );
}
