import Nav from '../components/Nav/index';
import BlogItem from '../components/BlogItem/index';
import { Link } from 'react-router-dom';

export default function Blog() {
  return (
    <div className="Blog">
      <Nav />
      <div className="row">
        <div className="column column-20" />
        <div className="column column-60">
          <BlogItem link={'2'} date={'October 26th, 2022'} title={'Dev Progress 2'} />
          <BlogItem link={'1'} date={'September 26th, 2022'} title={'Dev Progress 1'} />
        </div>
        <div class="column column-20" />
      </div>
    </div>
  );
}
