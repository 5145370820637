import Nav from '../../components/Nav/index';
import BlogTitle from '../../components/BlogTitle/index';
import Photo from '../../components/Photo/index';
import { useParams } from 'react-router-dom';

export default function DevPost1() {
  return (
    <div className="BlogPost">
      <Nav />
      <div className="row">
        <div className="column column-20" />
        <div className="column column-60">
          <BlogTitle title={'Dev Progress 1'} date={'September 26th, 2022'} />
          <Photo url={"https://images2.imgbox.com/52/d0/jsJMJ0d5_o.png"} subheading={"Living room draft"} />
          <Photo url={"https://images2.imgbox.com/8b/58/aE7ySMo0_o.png"} subheading={"Mesh scale testing"} />
          <Photo url={"https://images2.imgbox.com/bc/59/rjYsF0fX_o.png"} subheading={"Plant scale testing"} />
          <Photo url={"https://images2.imgbox.com/12/ad/7zLlCIel_o.png"} subheading={"Bedroom draft"} />
          <Photo url={"https://images2.imgbox.com/49/68/FxKIfkvg_o.png"} subheading={"Bathroom draft"} />
          <Photo url={"https://images2.imgbox.com/bf/cd/Ye8xkgNr_o.png"} subheading={"Plant painting test"} />
          <Photo url={"https://images2.imgbox.com/95/a8/C2XsByM9_o.png"} subheading={"Plant painting test 2"} />
        </div>
        <div class="column column-20" />
      </div>
    </div>
  );
}