import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styles from 'milligram';
import Home from './pages/Home';
import Blog from './pages/Blog';
import QA from './pages/QA';
import Downloads from './pages/Downloads';
import BlogPost from './pages/BlogPost';
import _1 from './pages/BlogPosts/DevPost1';
import _2 from './pages/BlogPosts/DevPost2';
import NoMatch from './pages/NoMatch';
import './css/index.css';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/qa" element={<QA />} />
        <Route path="/downloads" element={<Downloads />} />
        <Route path="/blog/1" element={<_1 />} />
        <Route path="/blog/2" element={<_2 />} />
        <Route path="/blog/:post" element={<BlogPost />} />
        {/* <Route path="login" element={<Login />} />
        <Route path="profile" element={<Profi/>} />
        <Route path="/reset/:token" element={<Reset />} /> */}
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </Router>
  );
}
