import React from 'react';

export default function BlogTitle({ title, date }) {
  return (
    <div>
      <h1 style={{color:"#444444"}}>{title}</h1>
      <h4>{date}</h4>
      <hr />
    </div>
  );
}
